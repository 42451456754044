$(function() {
    $('.cinema').each(function () {
        var cinema = $(this);
        var movies = cinema.find('.movies');
        var moviePanels = cinema.find('.movie-panel');
        var buyTicketsButton = cinema.find('.buy-tickets-button');

        var sessionLink = null;

        var updateButtonState = function () {
            buyTicketsButton.toggle(sessionLink !== null);
        };

        movies.find('a[data-target]').on('click', function () {
            var moviePanelSelector = $(this).attr('data-target');

            movies.hide();
            moviePanels.hide();
            moviePanels.filter(moviePanelSelector).show();
            window.scrollTo(0, $(moviePanelSelector).offset().top);
        });

        moviePanels.each(function () {
            var moviePanel = $(this);
            var dates = moviePanel.find('.dates');
            var timePanels = cinema.find('.movie-time-panel');
            var changeMovieButton = cinema.find('.change-movie-button');

            dates.find('a[data-target]').on('click', function () {
                var timePanelSelector = $(this).attr('data-target');

                dates.hide();
                timePanels.hide();
                timePanels.filter(timePanelSelector).show();
                window.scrollTo(0, $(timePanelSelector).offset().top);
            });

            timePanels.each(function () {
                var timePanel = $(this);
                var changeDateButton = cinema.find('.change-date-button');

                timePanel.find('a[data-session-link]').on('click', function () {
                    var timeLink = $(this);

                    timePanel.find('li').removeClass('active');
                    timeLink.closest('li').addClass('active');
                    sessionLink = $(this).attr('data-session-link');
                    updateButtonState();
                });

                changeDateButton.on('click', function () {
                    dates.show();
                    timePanels.hide();
                    sessionLink = null;
                    updateButtonState();
                })
            });

            changeMovieButton.on('click', function () {
                movies.show();
                moviePanels.hide();
                sessionLink = null;
                updateButtonState();
            })
        });

        buyTicketsButton.on('click', function () {
            window.location.href = sessionLink;
        });
    });

    $('.select-session').click(function() {
        $('html, body').animate({
            scrollTop: $('.buy-tickets-button').offset().top -50
        }, 1000);
    });

});
