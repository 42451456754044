$(function() {
    $('.gmap').each(function () {
        var isDraggable = $(document).width() > 480 ? true : false;
        var map = new GMaps({
            el: this,
            lat: parseFloat($(this).attr('data-lat')),
            lng: parseFloat($(this).attr('data-lng')),
            draggable: isDraggable,
            scrollwheel: false
        });
        map.setCenter(parseFloat($(this).attr('data-lat')), parseFloat($(this).attr('data-lng')));
        map.addMarker ({
            lat: parseFloat($(this).attr('data-lat')),
            lng: parseFloat($(this).attr('data-lng')),
            animation: google.maps.Animation.DROP,
            icon: '/img/ui/icon-map-marker.svg'
        });
    });
});
