$(function() {
    $('.open-menu').click(function() {
        $('body').addClass('open-nav');
    });
    $('.close-menu').click(function() {
        $('body').removeClass('open-nav');
    });

    if($(window).width() < 992) {

        // Accordion for the mobile nav submenu
        $('.dropdown-parent .alpha-link').click(function(e) {
            e.preventDefault();

            var $this = $(this);

            if($this.next().hasClass('show')) {
                $this.next().removeClass('show');
                $this.next().slideUp(350);
            } else {
                $this.parent().parent().find('.submenu').removeClass('show');
                $this.parent().parent().find('.submenu').slideUp(350);
                $this.next().toggleClass('show');
                $this.next().slideToggle('350');
            }

        });
        
    }

});
