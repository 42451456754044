$(function() {
    $('.scroll-down').click(function() {
        $('html, body').animate({
            scrollTop: $('.centre-bgd-pattern > .home-section-header').offset().top +20
        }, 1000);
    });
    $('.scroll-top').click(function() {
        $('html, body').animate({
            scrollTop: 0
        }, 1000);
    });

    // HIDING MOBILE TOOLBAR ON SCOLL DOWN
    // AND SHOWING AGAIN ON SCROLL UP
    var didScroll
    var lastScrollTop = 0;
    var delta = 1;
    var footerToolbarHeight = $('.mobile-toolbar').outerHeight();

    $(window).scroll(function(event) {
        didScroll = true;
    });

    setInterval(function() {
        if(didScroll) {
            hasScrolled();
            didScroll = false;
        }
    }, 250);

    function hasScrolled() {
        var st = $(this).scrollTop();

        // MAKE SURE THEY SCROLL MORE THAT DELTA
        if(Math.abs(lastScrollTop - st) <= delta)
            return;

        // IF THEY SCROLLED DOWN AND ARE PAST THE NAVBAR,
        // AND CLASS .NAV-UP. THIS IS NECESSARY SO YOU
        // NEVER SEE WHAT IS 'BEHIND' THE NAVBAR
        if (st > lastScrollTop && st > footerToolbarHeight) {
            // SCROLL DOWN
            $('.mobile-toolbar').removeClass('.is-visible').addClass('is-hidden');
        } else {
            // SCROLL UP
            if(st + $(window).height() < $(document).height()) {
                $('.mobile-toolbar').removeClass('is-hidden').addClass('.is-visible');
            }
        }

        lastScrollTop = st;
    }
});
