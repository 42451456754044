$(function() {
    var topbar = $('.topbar');

    $(window).scroll(function() {
        if($(this).scrollTop() > 48) {
            topbar.addClass('topbar-fixed');
        } else {
            topbar.removeClass('topbar-fixed');
        }
    });
    // topbar.on("scroll", function(e) {
    //     if(this.scrollTop > 48) {
    //         topbar.addClass('topbar-fixed');
    //     } else {
    //         topbar.removeClass('topbar-fixed');
    //     }
    // });
});
