$(function() {
    window.sr = ScrollReveal();
    sr.reveal('.sreveal', {
        duration: 800,
        easing: 'linear',
        distance: '30px',
        scale: 1
    });

    for (var increment = 1; increment <= 300; increment++) {
		if ($('.sreveal-delay-' + increment).length > 0) {
				sr.reveal('.sreveal-delay-' + increment, {
				delay: increment * 200
			});
		}
 	}

});
