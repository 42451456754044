$(function() {

    var $section = $('#centre-map');

    $section.find('.map-container').each(function () {
        var wrapper = $(this);
        var image = wrapper.find('.panzoom');

        image.panzoom ({
            $zoomIn: wrapper.find('.zoom-in'),
            $zoomOut: wrapper.find('.zoom-out'),
            $zoomRange: wrapper.find('.zoom-range'),
            $reset: wrapper.find('.reset'),
            startTransform: 'scale(1)',
            minScale: 1,
            cursor: "move",
            contain: 'invert'
        });

        var mc = new Hammer.Manager(image.get(0));
        mc.add(new Hammer.Tap({ event: 'doubletap', taps: 2 }));

        mc.on('doubletap', function (ev) {
           var zoom = parseFloat(image.panzoom('getMatrix')[0]) + 1;
           image.panzoom("zoom", zoom, {
               animate: true,
               focal: {
                   clientX: ev.center.x,
                   clientY: ev.center.y
               }
           });
        });
    });

    $section.find('[data-show-level]').click(function() {
        $section.find('.map-container').hide();
        $section.find('.map-level-' + $(this).attr('data-show-level')).show();
    });

    $section.find('[data-show-level]').first().click();
});
