$(function() {
    $('.carousel').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev slick-arrow"><i class="icon-caret-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next slick-arrow"><i class="icon-caret-right"></i></button>',
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
});
