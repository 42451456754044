$(function() {
    var containerEl = '#articles-wrapper';
    var mixer = mixitup(containerEl, {
        selectors: {
            target: '.article'
        },
        animation: {
            effects: 'fade scale',
            duration: 600
        }
    });
});
