$(function () {
    var isMobile = $(window).width() < 768;

    if(isMobile) {
        $('[data-mobile-background-image]').each(function () {
           $(this).css('background-image', 'url("' + $(this).attr('data-mobile-background-image') + '")');
        });
    } else {
        $('[data-desktop-background-image]').each(function () {
           $(this).css('background-image', 'url("' + $(this).attr('data-desktop-background-image') + '")');
        });
    }
});
